import "./PageStyle.css";
function MainPage() {
  return (
    <div>
      <section className="sec1">
        <div className="promt-text">
          <h1>Streç Tüketiminizi Azaltın</h1>
          <h2>%75'E KADAR DAHA VERIMLI</h2>
          <p>
            Streç Masraflarınız Çok mu Arttı? Streç Yüzeyinizi 5 Kata Kadar
            Arttırın Maliyetlerinizi Azaltın! TEK MOTORLU VE ÇIFT MOTORLU
            MAKINALAR Tek Motorlu ve Çift Motorlu Öngermeli Streçleme
            Makinaları, streç kullanım verimliliğinizi %75 oranında arttırır!
          </p>
        </div>
      </section>
      <section className="sec2">
        <h2>Pure CSS Sticky Nav Bar</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla
          imperdiet nec erat nec feugiat. Mauris vitae erat elit. Nunc sed
          libero ex. Duis lacinia nibh non efficitur placerat. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac
          turpis egestas. Phasellus a felis placerat, accumsan lacus quis,
          tempor magna. Nulla facilisi. Phasellus tempus, orci sit amet egestas
          pulvinar, dolor mi euismod justo, elementum finibus massa tellus nec
          tellus. Maecenas scelerisque nibh quis dolor blandit, vitae consequat
          dui lacinia. Phasellus at fringilla enim, eget suscipit risus. Morbi
          auctor aliquet erat sed lobortis. Quisque at quam purus. Nullam nec
          nisl imperdiet, rutrum dolor ut, malesuada erat. Proin elementum est
          ac ligula commodo consectetur. Praesent dapibus commodo ex non
          consectetur. Praesent vel elementum massa. Fusce sem turpis, cursus
          nec eleifend rhoncus, feugiat non erat. Etiam elit sapien, ullamcorper
          sed consequat ullamcorper, cursus a nisl. Vivamus tempus dui nec odio
          tincidunt molestie. Mauris ante odio, convallis vitae ligula eget,
          hendrerit auctor quam. Morbi at maximus justo. Vestibulum nec arcu
          ultrices, maximus turpis quis, euismod neque. Etiam consequat pulvinar
          porttitor. Maecenas vel risus justo. Maecenas eu lectus quis felis
          facilisis efficitur. Pellentesque interdum orci vitae egestas
          pharetra. Fusce tincidunt malesuada accumsan. Phasellus semper sodales
          dui sed viverra. Donec ut tellus nisl. Interdum et malesuada fames ac
          ante ipsum primis in faucibus. Sed quis auctor nulla. Donec nec turpis
          ullamcorper purus vulputate scelerisque ut sed diam. Ut at interdum
          urna, ut placerat odio. Duis ut tincidunt neque. Etiam nec maximus
          libero. Sed euismod, nisi ut egestas sodales, dui justo eleifend
          purus, non accumsan tellus sapien sed est. Nam nec augue a mi pharetra
          dapibus et nec sem. Aenean ligula velit, mattis ut turpis vitae,
          gravida condimentum lacus. Proin non convallis nunc. Donec quis
          convallis neque. Donec vulputate justo in ex convallis scelerisque.
          Proin consequat nunc odio, id ultrices massa placerat eget. Nullam a
          consequat arcu, id porta sem. Integer eget placerat lectus. Praesent
          quis ullamcorper metus, non porttitor ante. Quisque ac purus felis. In
          hac habitasse platea dictumst. Maecenas dapibus, metus sed pharetra
          condimentum, leo ante bibendum massa, at dictum nulla dui ac sapien.
          Vivamus semper, lacus at consectetur dapibus, felis libero consequat
          sapien, in pretium dolor augue vel urna. In eleifend, eros nec
          ultrices maximus, enim nibh sagittis enim, ac sagittis dui libero nec
          ipsum. In justo dui, consequat sed neque at, blandit condimentum ex.
          Duis vitae ultrices turpis, nec aliquam nisi. Vivamus lobortis velit
          elit, a ornare lorem finibus vitae. Pellentesque justo risus, varius
          eget placerat sit amet, ultrices ac nulla. Nunc sit amet gravida eros.
          Aenean in sem nunc. Donec risus lorem, commodo eu enim aliquet,
          iaculis dapibus nibh.
        </p>
      </section>
      <section className="sec3"></section>
    </div>
  );
}
export default MainPage;
