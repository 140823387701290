import odaksan_logo from "../../assets/odaksan-logo.webp";
import Menu from "../Menu/Menu";
import Social from "../Social/Social";
import "./Header.css";

function Header() {
  return (
    <>
      <div className="header">
        <Social />
        <img src={odaksan_logo} alt="" />
      </div>

      <>
        <Menu />
      </>
    </>
  );
}

export default Header;
