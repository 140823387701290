import facebook from "../../assets/facebook.webp";
import instagram from "../../assets/instagram.webp";
import linkedin from "../../assets/linkedin.webp";
import youtube from "../../assets/youtube.webp";
import "./Social.css";

function Social() {
  return (
    <div className="social">
      <div className="social-element">
        <a
          href="https://www.linkedin.com/company/odaksan/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={linkedin} alt="linkedin-logo" />
        </a>
      </div>
      <div className="social-element">
        <a
          href="https://www.facebook.com/Odaksan"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={facebook} alt="facebook-logo" />
        </a>
      </div>
      <div className="social-element">
        <a
          href="https://www.instagram.com/odaksan_muhendislik/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={instagram} alt="instagram-logo" />
        </a>
      </div>
      <div className="social-element" id="socail-logo">
        <a
          href="https://www.youtube.com/@odaksanmuhendislik3792"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={youtube} alt="youtube-logo" />
        </a>
      </div>
    </div>
  );
}

export default Social;
