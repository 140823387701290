import { default as menudata } from "../../data/menu.json";
import "./Menu.css";
const payload = menudata.menu;
function Menu() {
  return (
    <nav>
      <ul>
        {payload.map((menuitem, i) => {
          return (
            <li key={menuitem.slug} className="menu-element">
              <a href={`/${menuitem.slug}`}>{menuitem.name}</a>
            </li>
          );
        })}
      </ul>
    </nav>
  );
}

export default Menu;
