import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./Style.css";
import Header from "./components/Header/Header";
import MainPage from "./pages/MainPage";
import AboutUs from "./pages/AboutUs";
import Contact from "./pages/Contact";

function App() {
  return (
    <div className="container">
      <>
        <Header />
      </>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/hakkimizda" element={<AboutUs />} />
          <Route path="/iletisim" element={<Contact />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
